var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dash-admin"},[_c('language-selector'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-12 mt-5"},[_c('div',{staticClass:"card-white rounded card-project bigger"},[_c('div',{staticClass:"d-lg-flex"},[_c('div',{staticClass:"col-12 col-lg-12 p-0 d-flex flex-wrap"},[_c('div',{staticClass:"box-thumb-profile ml-0 p-0 position-relative"},[_c('img',{staticClass:"thumb-profile mr-4",attrs:{"src":'https://picsum.photos/600/600?random=' +
                    Math.round(Math.random() * 10),"alt":"Card image"}}),_vm._m(2)]),_vm._m(3),_vm._m(4)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-9 pd-highlight my-auto"},[_c('h1',[_c('span',[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"icons ico-back mb-4 mr-3"})])]),_vm._v(" Juliana Abreu ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 pd-highlight my-auto d-none d-lg-block"},[_c('div',{staticClass:"btn btn-primary mt-0"},[_vm._v("Logar como")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn-icons primary edit-btn",attrs:{"href":""}},[_c('i',{staticClass:"ico-edit-light"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-2 order-lg-1 col-lg-3 ml-lg-2 col-7 px-1"},[_c('h5',{staticClass:"pb-3"},[_vm._v("Enterprise S/A")]),_c('h5',{staticClass:"pb-1 d-inline-block"},[_vm._v("(11) 991216-2810")]),_c('i',{staticClass:"ico-edit-dark ml-lg-3 ml-1"}),_c('a',{staticClass:"d-inline-block",attrs:{"href":"#"}},[_c('h5',{staticClass:"underline pb-3"},[_vm._v(" juliana@enterprise.com.br ")])]),_c('i',{staticClass:"ico-edit-dark ml-lg-3 ml-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-3 order-lg-2 offset-lg-1 col-lg-3 col-5 px-1"},[_c('h5',{staticClass:"pb-1 d-inline-block"},[_vm._v("CPF")]),_c('i',{staticClass:"ico-edit-dark ml-lg-3 ml-1"}),_c('p',{staticClass:"pb-1 mb-0"},[_vm._v("336.096.289-48")]),_c('h5',{staticClass:"pb-1"},[_vm._v("Data de nascimento")]),_c('p',{staticClass:"pb-1 mb-0"},[_vm._v("15/ 06/ 1988")])])
}]

export { render, staticRenderFns }