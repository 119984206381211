<template>
  <div class="dash-admin">
    <language-selector />

    <div class="container">
      <div class="row">
        <div class="col-lg-9 pd-highlight my-auto">
          <h1>
            <span><a href="#"><i class="icons ico-back mb-4 mr-3"></i></a></span>
            Juliana Abreu
          </h1>
        </div>
        <div class="col-lg-3 pd-highlight my-auto d-none d-lg-block">
          <div class="btn btn-primary mt-0">Logar como</div>
        </div>
        <div class="col-12 mt-5">
          <div class="card-white rounded card-project bigger">
            <div class="d-lg-flex">
              <div class="col-12 col-lg-12 p-0 d-flex flex-wrap">
                <div class="box-thumb-profile ml-0 p-0 position-relative">
                  <img
                    :src="
                      'https://picsum.photos/600/600?random=' +
                      Math.round(Math.random() * 10)
                    "
                    alt="Card image"
                    class="thumb-profile mr-4"
                  />
                  <a href="" class="btn-icons primary edit-btn">
                    <i class="ico-edit-light"></i>
                  </a>
                </div>
                <div class="order-2 order-lg-1 col-lg-3 ml-lg-2 col-7 px-1">
                  <h5 class="pb-3">Enterprise S/A</h5>
                  <h5 class="pb-1 d-inline-block">(11) 991216-2810</h5>
                  <i class="ico-edit-dark ml-lg-3 ml-1"></i>
                  <a href="#" class="d-inline-block"
                    ><h5 class="underline pb-3">
                      juliana@enterprise.com.br
                    </h5></a
                  >
                  <i class="ico-edit-dark ml-lg-3 ml-1"></i>
                </div>
                <div class="order-3 order-lg-2 offset-lg-1 col-lg-3 col-5 px-1">
                  <h5 class="pb-1 d-inline-block">CPF</h5>
                  <i class="ico-edit-dark ml-lg-3 ml-1"></i>
                  <p class="pb-1 mb-0">336.096.289-48</p>
                  <h5 class="pb-1">Data de nascimento</h5>
                  <p class="pb-1 mb-0">15/ 06/ 1988</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log("Component mounted.");
  },
};
</script>
